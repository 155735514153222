<template>
  <div>
    <mosaic-card>
      <mosaic-card-title
        ><span>{{ selectedStudent.name }}</span>
      </mosaic-card-title>
      <v-divider class="mt-4" />
      <mosaic-tabs :headers="tabHeaders" @tab-selected="currentTab = $event">
        <template #studentDetails>
          <div class="d-flex flex-wrap">
            <div
              v-if="
                selectedStudent.ect &&
                selectedStudent.ect.end_of_year_2_review.status === 'approved' &&
                adminOnlyVisible
              "
              class="full-width"
            >
              <mosaic-info-alert>
                <div>
                  This ECT has an approved End of Year 2 Assessment meaning they can be be submitted as "Passed" with
                  the TRA. If you have already submitted this information to the TRA, you can Mark their induction as
                  complete on Mosaic here.
                </div>
                <div class="pt-2">
                  You should only Mark as complete on Mosaic once you have received confirmation from the TRA that the
                  Pass has been registered.
                </div>
                <div class="d-flex justify-center pt-2">
                  <mosaic-btn color="primary" @click.prevent="markInductionComplete()"
                    >Mark induction as complete</mosaic-btn
                  >
                </div>
              </mosaic-info-alert>
            </div>
            <div
              v-else-if="
                selectedStudent.ect &&
                !selectedStudent.ect.qts_checked_at &&
                !selectedStudent.ect.tra_qts_date &&
                selectedStudent.ect.trn &&
                selectedStudent.ect.date_of_birth &&
                adminOnlyVisible
              "
              class="full-width"
            >
              <mosaic-info-alert class="mt-2 mb-4">
                <div>QTS Status not confirmed</div>
                <div class="mt-2">
                  This ECT is awaiting a QTS check. Status checks should be completed within the hour. You can refresh
                  the page to see if the check has completed.
                </div>
              </mosaic-info-alert>
            </div>
            <div v-else-if="!selectedStudent.activated" class="full-width mb-2">
              <v-alert type="info" variant="outlined" density="compact">
                This {{ traineeNounCapitalised() }} has not logged in yet</v-alert
              >
            </div>
            <div
              :class="{
                'left-column': !smallScreen,
                'full-width': smallScreen,
              }"
            >
              <mosaic-select
                v-if="selectedStudent.ect"
                v-model="ectPackage"
                :readonly="readonly"
                prepend-icon="mdi-package"
                label="ECT Package"
                name="ect-package"
                item-title="name"
                item-value="ref"
                :items="validEctPackages"
              />
              <mosaic-text-field
                v-model="name"
                :readonly="readonly"
                name="name"
                label="Name"
                prepend-icon="mdi-account"
              />
              <div class="d-flex align-center">
                <mosaic-text-field
                  v-model="email"
                  :readonly="readonly"
                  name="email"
                  label="Email"
                  prepend-icon="mdi-email"
                  :disabled="selectedStudent.activated"
                  class="flex-grow-1"
                />
                <account-locked-chip v-if="selectedStudent.account_locked" class="pl-2" />
                <email-status-chip
                  v-if="isEmailVerificationOnForSelectedInstitution && !selectedStudent.is_demo"
                  class="ml-2"
                  :email-verified="selectedStudent.email_verified"
                  :email-bounced="selectedStudent.email_bounced"
                  :opted-out-of-emails="selectedStudent.opted_out_of_emails"
                />
                <div v-if="selectedStudent.activated && !readonly" class="ml-2">
                  <ndt-icon-button icon="pencil" tooltip="Edit email" @click.prevent="editEmail"></ndt-icon-button>
                </div>
              </div>
              <template v-if="selectedStudent.ect">
                <mosaic-date-picker
                  v-model:date="dateOfBirth"
                  name="date-of-birth"
                  :readonly="readonly"
                  label="Date of birth"
                  view-mode="year"
                  :exact-width="false"
                />
                <div class="d-flex">
                  <mosaic-text-field
                    v-model="trn"
                    prepend-icon="mdi-pound"
                    :readonly="readonly"
                    name="trn"
                    label="TRN"
                    :disabled="trainedOutsideEngland"
                    :rules="trainedOutsideEngland ? [] : adminTrnRules"
                    style="width: 250px"
                  />
                  <mosaic-checkbox
                    v-model="trainedOutsideEngland"
                    class="pl-2"
                    :disabled="readonly"
                    name="trained-outside-england"
                    label="Trained outside England"
                    :no-icon="true"
                  />
                </div>
                <v-alert
                  v-if="selectedStudent.ect.tra_qts_date"
                  class="ml-8 mt-3"
                  variant="outlined"
                  density="compact"
                  type="success"
                  >This {{ traineeNoun() }} has been awarded QTS</v-alert
                >
                <v-alert
                  v-if="!selectedStudent.ect.tra_qts_date && selectedStudent.ect.qts_checked_at"
                  class="ml-8 mt-3"
                  variant="outlined"
                  density="compact"
                  type="error"
                  >This {{ traineeNoun() }} has not been awarded QTS</v-alert
                >
                <mosaic-date-picker
                  v-model:date="qtsDate"
                  :readonly="readonly"
                  name="qts-date"
                  label="Date awarded QTS"
                  :exact-width="false"
                />
                <mosaic-date-picker
                  v-model:date="startDate"
                  :readonly="readonly"
                  name="start-date"
                  label="Start date"
                  :exact-width="false"
                />
                <mosaic-text-field
                  v-model="termsAlreadyCompleted"
                  :readonly="readonly"
                  prepend-icon="mdi-clipboard-text-clock"
                  name="terms-already-completed"
                  label="FTE terms of induction completed prior to joining this AB (do not include extension terms)"
                  type="number"
                  :rules="termsCompletedRules"
                />
                <template v-if="termsAlreadyCompleted > 0">
                  <div class="pl-8">
                    Have copies of the previous induction progress reviews/formal assessments been obtained from the
                    previous appropriate body?
                  </div>
                  <mosaic-select
                    v-model="priorReviewsObtained"
                    name="prior-reviews-obtained"
                    label="Prior reviews/assessments obtained?"
                    :items="priorReviewItems"
                    item-title="descriptor"
                    item-value="reviewsObtained"
                    prepend-icon="mdi-circle-check-outline"
                  />
                </template>
              </template>
              <template v-else>
                <mosaic-select
                  v-model="subjectId"
                  :readonly="readonly"
                  prepend-icon="mdi-pencil-ruler"
                  label="Main Subject"
                  name="main-subject"
                  item-title="name"
                  item-value="id"
                  :items="subjectsWithNullOption"
                />
                <mosaic-select
                  v-model="additionalSubjectId"
                  :readonly="readonly"
                  prepend-icon="mdi-pencil-ruler"
                  label="Additional Subject"
                  name="additional-subject"
                  item-title="name"
                  item-value="id"
                  :items="subjectsWithNullOption"
                  :disabled="!subjectId && !additionalSubjectId"
                />
                <mosaic-text-field
                  v-model="ittSchool"
                  :readonly="readonly"
                  name="school"
                  label="School/Setting"
                  prepend-icon="mdi-domain"
                />
                <mosaic-text-field
                  v-model="qualifications"
                  :readonly="readonly"
                  name="qualifications"
                  label="Qualifications"
                  prepend-icon="mdi-certificate"
                />
              </template>
              <template v-if="adminOnlyVisible">
                <mosaic-text-area
                  v-model="notes"
                  :readonly="readonly"
                  name="notes"
                  label="Notes"
                  rows="3"
                  prepend-icon="mdi-text"
                />
                <template v-if="selectedStudent.ect">
                  <v-alert v-if="ecfProgramme !== 'Full Induction Programme'" type="info" variant="outlined"
                    >Fidelity Check Required</v-alert
                  >
                  <mosaic-text-field
                    v-model="ecfCohort"
                    name="ecf-cohort"
                    label="ECF Cohort"
                    prepend-icon="mdi-school-outline"
                  ></mosaic-text-field>
                  <template v-if="isBrightFuturesAB">
                    <mosaic-text-field
                      v-model="invoiceRef1"
                      name="invoice-ref-1"
                      label="Invoice reference 1"
                      prepend-icon="mdi-currency-gbp"
                    ></mosaic-text-field>
                    <mosaic-text-field
                      v-model="invoiceRef2"
                      name="invoice-ref-2"
                      label="Invoice reference 2"
                      prepend-icon="mdi-currency-gbp"
                    ></mosaic-text-field>
                  </template>
                </template>
              </template>
            </div>
            <div
              :class="{
                'right-column': !smallScreen,
                'full-width': smallScreen,
              }"
            >
              <template v-if="selectedStudent.ect">
                <div class="d-flex align-center">
                  <mosaic-text-field
                    :readonly="true"
                    name="ect-school"
                    label="School"
                    :model-value="selectedStudent.ect.current_school && selectedStudent.ect.current_school.display_name"
                    :link="
                      (selectedStudent.ect.current_school &&
                        (hasPermissionForSchool(userStaff, 'schools.edit', selectedStudent.ect.current_school) ||
                          hasPermissionForSchool(userStaff, 'schools.view', selectedStudent.ect.current_school)) && {
                          name: 'TutorSchoolStudentsPage',
                          params: {
                            institutionId: selectedInstitution.id,
                            schoolId: selectedStudent.ect.current_school.id,
                          },
                        }) ||
                      null
                    "
                    prepend-icon="mdi-domain"
                    :class="{ 'mr-2': userStaffHasPermission('schools.edit') }"
                  />
                  <ndt-icon-button
                    v-if="userStaffHasPermission('schools.edit')"
                    icon="home-edit-outline"
                    tooltip="Update via Manage Induction page"
                    @click.prevent="editSchool"
                  ></ndt-icon-button>
                </div>
              </template>
              <mosaic-text-field
                v-model="addressFirstLine"
                :readonly="readonly"
                prepend-icon="mdi-postage-stamp"
                name="address-1"
                label="Address Line 1"
              />
              <mosaic-text-field
                v-model="addressSecondLine"
                :readonly="readonly"
                prepend-icon="mdi-postage-stamp"
                name="address-2"
                label="Address Line 2"
              />
              <mosaic-text-field
                v-model="addressCity"
                :readonly="readonly"
                prepend-icon="mdi-postage-stamp"
                name="address-city"
                label="City"
              />
              <mosaic-text-field
                v-model="addressPostcode"
                :readonly="readonly"
                prepend-icon="mdi-postage-stamp"
                name="address-postcode"
                label="Post Code"
              />
              <mosaic-text-field
                v-model="addressCountry"
                :readonly="readonly"
                prepend-icon="mdi-postage-stamp"
                name="address-country"
                label="Country"
              />
              <mosaic-text-field
                v-model="phoneNumber"
                :readonly="readonly"
                prepend-icon="mdi-phone"
                name="contact-number"
                label="Contact Number"
              />
              <div class="d-flex align-center">
                <div>
                  <mosaic-checkbox
                    name="demo-account-check"
                    v-model="isDemo"
                    class="mr-2 ml-0"
                    hide-details
                    :disabled="readonly"
                    density="compact"
                    label="Demo Account"
                    no-icon
                  />
                </div>
                <mosaic-help>
                  <div>Demo accounts are intended for testing or demonstrating functionality</div>
                  <div>and will not trigger any automated emails from Mosaic.</div>
                </mosaic-help>
              </div>
              <force-microsoft-sso-checkbox v-model="forceMicrosoftSso" />
            </div>
          </div>
          <v-alert density="compact" class="my-4" v-if="saveTraineeDetailsError" type="error">{{
            saveTraineeDetailsError
          }}</v-alert>
          <mosaic-alert v-if="subjectIncompatibleWeeks.length > 0" class="my-4" type="warning">
            <div>
              The following Training Plan Weeks will not display any Pages to this {{ traineeNounCapitalised() }}, as
              they contain only subject-specific Pages, which the chosen {{ traineeNounCapitalised() }} would not have
              access to via the selected Subject(s):
              <ul class="pt-2">
                <li v-for="w in subjectIncompatibleWeeks" :key="w.name">
                  {{ w.name }} ({{ w.cohortCourseTerm.name }})
                </li>
              </ul>
              <div class="pt-2">Please contact support if you need help with this issue.</div>
            </div>
          </mosaic-alert>
          <div v-if="!readonly" class="d-flex justify-end">
            <mosaic-snackbar
              v-model="traineeDetailsSnackbar.active"
              color="success"
              :message="traineeDetailsSnackbar.message"
              location="bottom"
            />
            <v-btn color="primary" :disabled="!canSaveTraineeDetails" @click.prevent="saveTraineeDetails()">
              Save
            </v-btn>
          </div>
        </template>
        <template #contractDetails>
          <div class="d-flex flex-wrap">
            <div class="left-column">
              <mosaic-select
                v-model="contractType"
                :readonly="readonly"
                name="contract-type"
                label="Contract type"
                :items="contractTypeItems"
                prepend-icon="mdi-file-sign"
              />
            </div>
            <div class="right-column">
              <mosaic-select
                v-model="employmentType"
                :readonly="readonly"
                name="employment-type"
                label="Employment Type"
                :items="employmentTypeOptions"
                prepend-icon="mdi-timetable"
              />
              <mosaic-select
                v-if="employmentType === 'Part Time'"
                v-model="ptFteRate"
                :readonly="readonly"
                name="pt-fte-rate"
                label="Full time equivalent (FTE) fraction"
                :items="partTimeOptions"
                item-title="descriptor"
                item-value="rate"
                prepend-icon="mdi-timetable"
                return-object
              />
            </div>
            <div class="left-column">
              <mosaic-text-area
                v-if="showContractDetails"
                v-model="contractDetails"
                :readonly="readonly"
                name="contract-details"
                label="Contract details"
                rows="2"
                prepend-icon="mdi-file-sign"
              />
            </div>
            <div class="right-column"></div>
            <div class="left-column">
              <mosaic-date-picker
                v-if="showContractDetails"
                v-model:date="contractStartDate"
                :readonly="readonly"
                name="contract-start"
                label="Contract start date"
                prepend-icon="mdi-calendar"
                view-mode="month"
                :exact-width="false"
              />
            </div>
            <div class="right-column"></div>
            <div class="left-column">
              <mosaic-date-picker
                v-if="showContractDetails"
                v-model:date="contractEndDate"
                :readonly="readonly"
                name="contract-end"
                label="Contract end date"
                prepend-icon="mdi-calendar"
                view-mode="month"
                :exact-width="false"
              />
            </div>
            <div class="right-column"></div>
            <div class="full-width">
              <mosaic-autocomplete
                v-model="yearGroups"
                :readonly="readonly"
                name="year-groups"
                :items="yearGroupItems"
                multiple
                label="Year groups being taught"
                no-data-text="No year groups found"
                prepend-icon="mdi-numeric"
              />
              <mosaic-text-field
                v-if="yearGroups.includes('Other')"
                v-model="otherYearGroup"
                :readonly="readonly"
                name="other-year-group"
                label="Other year group, please specify"
                prepend-icon="mdi-numeric"
              />
            </div>
            <div class="full-width">
              <mosaic-autocomplete
                v-model="teachingSubjects"
                :readonly="readonly"
                name="teaching-subjects"
                :items="ectRegistrationSubjectsIncludingOther"
                multiple
                label="Subjects being taught"
                no-data-text="No subjects found"
                prepend-icon="mdi-pencil-ruler"
              />
              <mosaic-text-field
                v-if="teachingSubjects.includes('Other')"
                v-model="otherTeachingSubject"
                :readonly="readonly"
                name="other-teaching-subject"
                label="Other subject, please specify"
                prepend-icon="mdi-pencil-ruler"
              />
            </div>
            <div class="full-width">
              <mosaic-card-subheading>Induction Support</mosaic-card-subheading>
            </div>
            <div class="left-column">
              <mosaic-text-field
                v-model="mentorName"
                :readonly="readonly"
                name="mentor-name"
                label="Mentor Name"
                prepend-icon="mdi-account"
              />
            </div>
            <div class="right-column">
              <mosaic-text-field
                v-model="coordinatorName"
                :readonly="readonly"
                name="coordinator-name"
                label="Induction Coordinator Name"
                prepend-icon="mdi-account"
              />
            </div>
            <div class="left-column">
              <mosaic-text-field
                v-model="mentorEmail"
                :readonly="readonly"
                name="mentor-email"
                label="Mentor Email"
                prepend-icon="mdi-account"
              />
            </div>
            <div class="right-column">
              <mosaic-text-field
                v-model="coordinatorEmail"
                :readonly="readonly"
                name="coordinator-email"
                label="Induction Coordinator Email"
                prepend-icon="mdi-account"
              />
            </div>
            <div class="left-column">
              <mosaic-text-field
                v-model="mentorJobTitle"
                :readonly="readonly"
                name="mentor-job-title"
                label="Mentor Job Title"
                prepend-icon="mdi-account"
              />
            </div>
            <div class="right-column">
              <mosaic-text-field
                v-model="coordinatorJobTitle"
                :readonly="readonly"
                name="coordinator-job-title"
                label="Induction Coordinator Job Title"
                prepend-icon="mdi-account"
              />
            </div>
            <template v-if="ectPackage === 'ab_and_ecf'">
              <div class="left-column">
                <mosaic-text-field
                  v-model="mentorTrn"
                  :readonly="readonly"
                  prepend-icon="mdi-pound"
                  name="mentor-trn"
                  label="Mentor TRN"
                  :rules="adminTrnRules"
                />
              </div>
              <div class="right-column" />
              <div class="left-column">
                <mosaic-text-area
                  v-model="mentorTrainingDetails"
                  :readonly="readonly"
                  prepend-icon="mdi-text"
                  name="mentor-training-details"
                  label="Mentor training details"
                  variant="filled"
                ></mosaic-text-area>
              </div>
            </template>
          </div>

          <mosaic-error-alert class="my-4" :override-error-message="saveContractDetailsError" />
          <div v-if="!readonly" class="d-flex justify-end">
            <mosaic-snackbar
              v-model="contractDetailsSnackbar.active"
              color="success"
              :message="contractDetailsSnackbar.message"
            />
            <v-btn color="primary" :disabled="!canSaveContractDetails" @click.prevent="saveContractDetails()">
              Save
            </v-btn>
          </div>
        </template>
        <template #trainingDetails>
          <mosaic-text-field
            v-model="teacherTrainingInstitution"
            :readonly="readonly"
            name="teacher-training-institution"
            :label="`Teacher training institution${
              selectedStudent.ect.tra_itt_establishment ? ' (manually entered)' : ''
            }`"
            prepend-icon="mdi-school"
          />
          <mosaic-text-field
            v-if="selectedStudent.ect.tra_itt_establishment"
            v-model="selectedStudent.ect.tra_itt_establishment"
            :readonly="true"
            name="tra-teacher-training-institution"
            label="Teacher training institution (from TRA)"
            prepend-icon="mdi-school"
          />
          <mosaic-select
            v-model="qualificationsAwarded"
            :readonly="readonly"
            name="qualifications-awarded"
            label="Qualifications awarded"
            item-value="value"
            item-title="title"
            :items="
              qualificationsAwardedItems.map(a => {
                return { title: a, value: a };
              })
            "
            multiple
            prepend-icon="mdi-certificate"
          />
          <mosaic-autocomplete
            v-model="qualifiedSubjects"
            :readonly="readonly"
            name="qualified-subjects"
            label="Qualified subjects"
            :items="ectRegistrationSubjectsIncludingOther"
            no-data-text="No subjects found"
            multiple
            prepend-icon="mdi-pencil-ruler"
          />
          <mosaic-text-field
            v-if="qualifiedSubjects.includes('Other')"
            v-model="otherQualifiedSubject"
            name="other-qualified-subject"
            label="Other Subject, please specify"
            prepend-icon="mdi-pencil-ruler"
            :readonly="readonly"
          />
          <mosaic-select
            v-model="qualifiedAgeRanges"
            :readonly="readonly"
            name="qualified-age-ranges"
            label="Qualified age ranges"
            item-value="value"
            item-title="title"
            :items="
              qualifiedAgeRangesItemsIncludingOther.map(a => {
                return { title: a, value: a };
              })
            "
            multiple
            prepend-icon="mdi-numeric"
          />
          <mosaic-text-field
            v-if="qualifiedAgeRanges.includes('Other')"
            v-model="otherQualifiedAgeRange"
            name="other-qualified-age-range"
            label="Other age range, please specify"
            prepend-icon="mdi-numeric"
            :readonly="readonly"
          />
          <template v-if="ectPackage === 'ab_only'">
            <mosaic-select
              v-model="ecfProgramme"
              :readonly="readonly"
              name="ecf-programme"
              label="ECF programme"
              :items="ecfProgrammeItems"
              prepend-icon="mdi-school-outline"
            />
            <mosaic-select
              v-if="showEcfProvider"
              v-model="ecfProvider"
              :readonly="readonly"
              name="ecf-provider"
              label="ECF provider"
              :items="ecfProviderItems"
              prepend-icon="mdi-school-outline"
            />
          </template>
          <template v-else-if="ectPackage === 'ab_and_ecf'">
            <mosaic-select
              v-model="ecfTermsCompleted"
              :readonly="readonly"
              name="ecf-cohort"
              label="ECF terms completed"
              :items="ecfTermsCompletedItems"
              item-value="termsCompleted"
              prepend-icon="mdi-school-outline"
            />
          </template>
          <mosaic-error-alert class="my-4" :override-error-message="saveTrainingDetailsError" />
          <div v-if="!readonly" class="d-flex justify-end">
            <mosaic-snackbar
              v-model="trainingDetailsSnackbar.active"
              color="success"
              :message="trainingDetailsSnackbar.message"
            />
            <v-btn color="primary" :disabled="!canSaveTrainingDetails" @click.prevent="saveTrainingDetails()">
              Save
            </v-btn>
          </div>
        </template>
        <template #ectDeclarations>
          <template v-if="registeringName">
            <mosaic-card-subheading>Details of registering staff member</mosaic-card-subheading>
            <mosaic-text-field
              v-model="registeringName"
              :readonly="true"
              name="registering-name"
              label="Registering staff name"
              prepend-icon="mdi-account"
              style="width: 60%"
            />
            <mosaic-text-field
              v-model="registeringEmail"
              :readonly="true"
              name="registering-email"
              label="Registering staff email"
              prepend-icon="mdi-at"
              style="width: 60%"
            />
            <mosaic-text-field
              v-model="registeringRole"
              :readonly="true"
              name="registering-role"
              label="Registering staff role"
              prepend-icon="mdi-briefcase"
              style="width: 60%"
            />
            <mosaic-card-subheading>Agreements</mosaic-card-subheading>
            <template v-if="selectedInstitution.ab_sla_link">
              <mosaic-icon-link
                :link="selectedInstitution.ab_sla_link"
                :label="`${selectedInstitution.name} SLA`"
                icon="file-pdf-box"
              ></mosaic-icon-link>
              <mosaic-checkbox v-model="slaAgreed" name="sla-check" label="SLA agreed by school" :disabled="true" />
            </template>
            <mosaic-checkbox
              v-if="isBrightFuturesAB"
              v-model="marketingAgreed"
              name="marketing-check"
              label="School has agreed to receive marketing communications"
              :disabled="true"
            />
            <template v-if="!(isBrightFuturesAB || selectedInstitution.ab_sla_link)"
              ><div>There are no SLAs or marketing agreements to declare</div></template
            ></template
          >
          <template v-else
            ><div>
              There are no declarations as this ECT was registered by institution administrators rather than
              administrators at the ECT's school.
            </div>
          </template>
        </template>
        <template #access>
          <div class="text-h6">{{ traineeNounCapitalised() }} level access</div>
          <mosaic-list
            :items="studentScopedStaffRoles"
            :empty-text="`There are no Instructors with ${traineeNounCapitalised()} level access`"
          >
            <template #item="{ item: s }">
              <mosaic-list-item icon="mdi-human-male-board" :title="renderStaffName(s)" :subtitle="s.role.name">
                <template #actions v-if="userStaffHasPermission('staff.edit')">
                  <ndt-icon-button icon="close" tooltip="Remove" @click.prevent="removeStudentScopedRole(s)" />
                </template>
              </mosaic-list-item>
            </template>
          </mosaic-list>

          <v-alert density="compact" class="mt-4 mb-4" v-if="studentScopedRoleError" type="error">
            {{ studentScopedRoleError }}
          </v-alert>
          <div v-if="userStaffHasPermission('staff.edit')" class="w-100 d-flex align-center">
            <div class="d-flex flex-grow-1 pl-2">
              <v-autocomplete
                v-model="studentScopedStaffIdToAdd"
                class="mr-3 w-50"
                :items="staffItems"
                placeholder="Instructors"
                :custom-filter="filterStaff"
                item-title="name"
                item-value="id"
                :no-data-text="
                  loadStaffBusy
                    ? `Instructors loading...`
                    : loadStaffError
                    ? `Sorry, there was an error loading Instructors`
                    : `No Instructors available`
                "
              >
              </v-autocomplete>
              <v-select
                v-model="studentScopedRoleId"
                class="mr-3 w-50"
                :items="studentScopedRoles"
                placeholder="Role"
                item-title="name"
                item-value="id"
              />
            </div>
            <v-btn variant="text" ripple :disabled="!canAddStudentScopedRole" @click.prevent="addStudentScopedRole()"
              >Add</v-btn
            >
          </div>

          <template v-if="cohortScopedStaffRoles.length > 0">
            <div class="text-h6 mt-2">Cohort level access</div>
            <mosaic-list :items="cohortScopedStaffRoles">
              <template #item="{ item: s }">
                <mosaic-list-item icon="mdi-human-male-board" :title="s.name || s.email" :subtitle="s.role.name">
                </mosaic-list-item>
              </template>
            </mosaic-list>
          </template>

          <div class="text-h6 mt-2">Institution level access</div>
          <mosaic-list :items="institutionScopedStaffRoles">
            <template #item="{ item: s }">
              <mosaic-list-item icon="mdi-human-male-board" :title="renderStaffName(s)" :subtitle="s.role.name">
              </mosaic-list-item>
            </template>
          </mosaic-list>
        </template>
      </mosaic-tabs>
    </mosaic-card>

    <ndt-dialog
      v-model:active="editEmailDialog.active"
      :error-message="editEmailDialog.errorMessage"
      title="Edit Email"
    >
      <v-alert type="warning"
        >The {{ traineeNoun() }}'s email is used to login. Please let them know if you change it. They will need to
        verify this email when they next login.</v-alert
      >
      <v-text-field v-model="editEmailDialog.email" label="Email" />
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          :disabled="editEmailDialog.processing || !editEmailDialog.email"
          @click.prevent="submitEditEmail()"
          >Save</v-btn
        >
      </template>
    </ndt-dialog>

    <ndt-dialog
      v-model:active="editSchoolDialog.active"
      :error-message="editSchoolDialog.errorMessage"
      title="Edit School"
    >
      <mosaic-autocomplete
        v-model="editSchoolDialog.school"
        name="school"
        :items="schools"
        item-value="id"
        item-title="displayName"
        label="School"
        no-data-text="No schools found"
        prepend-icon="mdi-domain"
      />
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          :disabled="editSchoolDialog.processing || !editSchoolDialog.school"
          @click.prevent="submitEditSchool()"
          >Save</v-btn
        >
      </template>
    </ndt-dialog>

    <unsaved-changes-dialog
      v-model:unsaved-changes-dialog="unsavedChangesDialog"
      :object-type="traineeNounCapitalised()"
    />
    <induction-complete-dialog
      v-if="selectedStudent && selectedStudent.ect"
      v-model:active="inductionCompletionDialog.active"
      :ect="selectedStudent.ect"
      @submit="$router.go(-1)"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { validateEmail } from '../../utils/email';
import { termsCompletedRules, validationsPass, fteRateRules, adminTrnRules } from '../../utils/validations';
import NdtIconButton from '../../components/NdtIconButton.vue';
import InductionCompleteDialog from '@/components/InductionCompleteDialog.vue';
import NdtDialog from '../../components/NdtDialog.vue';
import { syncQueryParamsMixin } from '../../mixins/query-mixins';
import { useQueryStore } from '@/stores/query';
import {
  yearGroupItems,
  qualifiedAgeRangesItems,
  contractTypeItems,
  qualificationsAwardedItems,
  ecfProgrammeItems,
  ecfProviderItems,
  ectRegistrationSubjects,
  ecfTermsCompletedItems,
  partTimeOptions,
} from '../../utils/ect/ect';
import unsavedChangesMixin from '../../components/unsaved-changes-mixin';
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog.vue';
import EmailStatusChip from '@/components/user/EmailStatusChip.vue';
import AccountLockedChip from '@/components/user/AccountLockedChip.vue';
import MosaicIconLink from '@/components/library/display/MosaicIconLink.vue';
import { useInstitutionStaffStore } from '@/stores/institution-staff';
import ForceMicrosoftSsoCheckbox from '@/components/ForceMicrosoftSsoCheckbox.vue';

export default {
  name: 'TutorStudentDetailsPage',
  components: {
    NdtIconButton,
    NdtDialog,
    UnsavedChangesDialog,
    EmailStatusChip,
    AccountLockedChip,
    MosaicIconLink,
    InductionCompleteDialog,
    ForceMicrosoftSsoCheckbox,
  },
  mixins: [
    unsavedChangesMixin,
    syncQueryParamsMixin({
      tab: { query: 'tab', type: 'integer' },
    }),
  ],
  setup() {
    const {
      actions: { clearInstitutionStaff },
    } = useInstitutionStaffStore();
    const queryStore = useQueryStore();
    return { clearInstitutionStaff, queryStore };
  },
  data: function () {
    return {
      inductionCompletionDialog: {
        active: false,
      },
      error: '',

      studentScopedRoleError: '',
      tab: 0,
      ectPackage: null,
      name: '',
      email: '',
      dateOfBirth: '',
      trn: '',
      trainedOutsideEngland: false,
      qtsDate: '',
      startDate: '',
      termsAlreadyCompleted: '',
      priorReviewsObtained: null,
      addressFirstLine: '',
      addressSecondLine: '',
      addressCity: '',
      addressPostcode: '',
      addressCountry: '',
      phoneNumber: '',
      ittSchool: '',
      qualifications: '',
      notes: '',
      ecfTermsCompletedItems,
      ecfTermsCompleted: null,
      subjectId: null,
      additionalSubjectId: null,
      saveTraineeDetailsProcessing: false,
      saveTraineeDetailsError: '',
      ptFteRate: 1,
      partTimeOptions,
      employmentTypeOptions: ['Full Time', 'Part Time'],
      employmentType: 'Full Time',
      contractType: '',
      contractDetails: '',
      contractStartDate: '',
      contractEndDate: '',
      mentorName: '',
      mentorEmail: '',
      mentorJobTitle: '',
      mentorTrn: '',
      mentorTrainingDetails: '',
      coordinatorName: '',
      coordinatorEmail: '',
      coordinatorJobTitle: '',
      yearGroups: [],
      otherYearGroup: '',
      teachingSubjects: [],
      otherTeachingSubject: '',
      saveContractDetailsProcessing: false,
      saveContractDetailsError: '',
      teacherTrainingInstitution: '',
      saveTrainingDetailsProcessing: false,
      saveTrainingDetailsError: '',
      ectRegistrationSubjects,
      otherQualifiedSubject: '',
      qualificationsAwarded: [],
      qualifiedSubjects: [],
      qualifiedAgeRanges: [],
      otherQualifiedAgeRange: '',
      ecfProgramme: null,
      ecfProvider: '',
      isDemo: false,
      forceMicrosoftSso: true,
      editEmailDialog: {
        active: false,
        email: '',
        errorMessage: '',
        processing: false,
      },
      editSchoolDialog: {
        active: false,
        school: null,
        errorMessage: '',
        processing: false,
      },
      schools: [],
      studentScopedStaffIdToAdd: null,
      studentScopedRoleId: null,
      studentScopedRoleProcessing: false,
      staff: [],
      yearGroupItems,
      qualifiedAgeRangesItems,
      contractTypeItems,
      qualificationsAwardedItems,
      ecfProgrammeItems,
      ecfProviderItems,
      termsCompletedRules,
      fteRateRules,
      adminTrnRules,
      registeringEmail: '',
      registeringName: '',
      registeringRole: '',
      slaAgreed: false,
      marketingAgreed: false,
      ecfCohort: '',
      invoiceRef1: '',
      invoiceRef2: '',
      retryQtsProcessing: false,
      retryQtsErrorAction: '',
      traineeDetailsSnackbar: {
        active: false,
        message: '',
      },
      contractDetailsSnackbar: {
        active: false,
        message: '',
      },
      trainingDetailsSnackbar: {
        active: false,
        message: '',
      },
      loadStaffBusy: true,
      loadStaffError: false,
      studentWeeksWithSubjects: [],
      loadWeeksError: '',
      loadWeeksProcessing: false,
    };
  },
  computed: {
    ...mapState(['selectedStudent', 'subjects', 'roles', 'selectedInstitution', 'userStaff', 'selectedCohort']),
    ...mapGetters(['isEmailVerificationOnForSelectedInstitution', 'ectPackages', 'isBrightFuturesAB']),
    breadcrumbs() {
      return [
        {
          text: 'Details',
        },
      ];
    },
    priorReviewItems() {
      return [
        {
          reviewsObtained: true,
          descriptor: 'Yes - we have obtained copies from the appropriate body',
        },
        {
          reviewsObtained: false,
          descriptor: `No - we do not have copies and would like ${this.selectedInstitution.name} to obtain these on our behalf`,
        },
      ];
    },
    subjectIncompatibleWeeks() {
      // Detects whether the currently selected subject(s) are such that there is at least one week where the student would not see any Pages.
      if (!this.studentWeeksWithSubjects?.length) return [];
      const subjectIds = [this.subjectId, this.additionalSubjectId].filter(x => x);
      const incompatibleWeeks = [];
      this.studentWeeksWithSubjects.forEach(sw => {
        if (sw.cohortCourseWeek.sectionSubjectIds.every(id => id !== null && !subjectIds.includes(id))) {
          incompatibleWeeks.push(sw.cohortCourseWeek);
        }
      });
      return incompatibleWeeks;
    },
    canRetryQts() {
      return !this.retryQtsProcessing && this.dateOfBirth && this.trn && validationsPass(this.adminTrnRules, this.trn);
    },
    ectRegistrationSubjectsIncludingOther() {
      return this.ectRegistrationSubjects.concat(['Other']);
    },
    qualifiedAgeRangesItemsIncludingOther() {
      return this.qualifiedAgeRangesItems.concat(['Other']);
    },
    yearGroupItemsIncludingOther() {
      return this.yearGroupItems.concat(['Other']);
    },
    qualifiedSubjectsToSave() {
      return this.otherTransformedSelection(this.qualifiedSubjects, this.otherQualifiedSubject);
    },
    qualifiedAgeRangesToSave() {
      return this.otherTransformedSelection(this.qualifiedAgeRanges, this.otherQualifiedAgeRange);
    },
    yearGroupsToSave() {
      return this.otherTransformedSelection(this.yearGroups, this.otherYearGroup);
    },
    teachingSubjectsToSave() {
      return this.otherTransformedSelection(this.teachingSubjects, this.otherTeachingSubject);
    },
    validEctPackages() {
      return this.ectPackages.filter(x => x.ref != 'ecf_only');
    },
    fteRate() {
      return this.employmentType === 'Full Time' ? 1.0 : this.ptFteRate?.rate;
    },
    tabHeaders() {
      return [
        { text: `${this.traineeNounCapitalised()} Details`, dirty: this.traineeDetailsDirty, key: 'studentDetails' },
        {
          text: 'Contract Details',
          dirty: this.contractDetailsDirty,
          hide: !this.selectedStudent.ect,
          key: 'contractDetails',
        },
        {
          text: 'Training Details',
          dirty: this.trainingDetailsDirty,
          hide: !this.selectedStudent.ect,
          key: 'trainingDetails',
        },
        { text: 'ECT Declarations', dirty: false, hide: !this.selectedStudent.ect, key: 'ectDeclarations' },
        { text: 'Account Access', dirty: false, key: 'access' },
      ].filter(x => !x.hide);
    },
    readonly() {
      return !this.userStaffHasPermissionForSelectedStudent('Admin');
    },
    adminOnlyVisible() {
      return !this.readonly;
    },
    traineeDetailsDirty() {
      return (
        this.name !== this.selectedStudent.name ||
        this.email !== this.selectedStudent.email ||
        this.addressFirstLine !== this.selectedStudent.address_first_line ||
        this.addressSecondLine !== this.selectedStudent.address_second_line ||
        this.addressCity !== this.selectedStudent.address_city ||
        this.addressPostcode !== this.selectedStudent.address_postcode ||
        this.addressCountry !== this.selectedStudent.address_country ||
        this.phoneNumber !== this.selectedStudent.phone_number ||
        this.ittSchool !== this.selectedStudent.school ||
        this.qualifications !== this.selectedStudent.qualifications ||
        this.notes !== this.selectedStudent.notes ||
        this.isDemo !== this.selectedStudent.is_demo ||
        this.forceMicrosoftSso !== this.selectedStudent.force_microsoft_sso ||
        // Deliberately double ==
        this.subjectId != this.selectedStudent.subject?.id ||
        this.additionalSubjectId != this.selectedStudent.additional_subject?.id ||
        (this.selectedStudent.ect &&
          (this.ectPackage != this.selectedStudent.ect.institution_ect_package ||
            this.startDate !== this.selectedStudent.ect.start_date ||
            this.qtsDate !== this.selectedStudent.ect.date_awarded_qts ||
            parseFloat(this.termsAlreadyCompleted) !== this.selectedStudent.ect.terms_already_completed ||
            (this.termsAlreadyCompleted > 0 &&
              this.priorReviewsObtained !== this.selectedStudent.ect.prior_reviews_obtained) ||
            this.trn != this.selectedStudent.ect.trn ||
            this.ecfCohort !== this.selectedStudent.ect.ecf_cohort ||
            this.invoiceRef1 !== this.selectedStudent.ect.invoice_ref_1 ||
            this.invoiceRef2 !== this.selectedStudent.ect.invoice_ref_2 ||
            this.dateOfBirth != this.selectedStudent.ect.date_of_birth))
      );
    },
    canSaveTraineeDetails() {
      return (
        !this.saveTraineeDetailsProcessing &&
        this.name &&
        this.email &&
        (!this.selectedStudent.ect ||
          (this.startDate &&
            validationsPass(this.termsCompletedRules, this.termsAlreadyCompleted) &&
            (validationsPass(this.adminTrnRules, this.trn) || !this.trn) &&
            (this.termsAlreadyCompleted === 0 || this.priorReviewsObtained !== null)))
      );
    },
    contractDetailsDirty() {
      if (!this.selectedStudent.ect) return false;
      return (
        ((this.fteRate || this.selectedStudent.ect.fte_rate) &&
          parseFloat(this.fteRate) !== this.selectedStudent.ect.fte_rate) ||
        this.contractType !== this.selectedStudent.ect.contract_type ||
        this.contractDetails !== this.selectedStudent.ect.contract_details ||
        this.contractStartDate !== this.selectedStudent.ect.contract_start_date ||
        this.contractEndDate !== this.selectedStudent.ect.contract_end_date ||
        this.yearGroupsToSave.length !== this.selectedStudent.ect.year_groups.length ||
        this.yearGroupsToSave.some(x => !this.selectedStudent.ect.year_groups.includes(x)) ||
        this.teachingSubjectsToSave.length !== this.selectedStudent.ect.teaching_subjects.length ||
        this.teachingSubjectsToSave.some(x => !this.selectedStudent.ect.teaching_subjects.includes(x)) ||
        this.mentorName !== this.selectedStudent.ect.mentor_name ||
        this.mentorEmail !== this.selectedStudent.ect.mentor_email ||
        this.mentorJobTitle !== this.selectedStudent.ect.mentor_job_title ||
        this.mentorTrn !== this.selectedStudent.ect.mentor_trn ||
        this.mentorTrainingDetails !== this.selectedStudent.ect.mentor_training_details ||
        this.coordinatorName !== this.selectedStudent.ect.coordinator_name ||
        this.coordinatorEmail !== this.selectedStudent.ect.coordinator_email ||
        this.coordinatorJobTitle !== this.selectedStudent.ect.coordinator_job_title
      );
    },
    // Different to EctRegistration as have to allow for ECTs who didn't go through that flow
    canSaveContractDetails() {
      return (
        !this.saveContractDetailsProcessing &&
        this.fteRateRules.every(r => r(this.fteRate)) &&
        (this.ectPackage !== 'ab_and_ecf' || validationsPass(this.adminTrnRules, this.mentorTrn))
      );
    },
    trainingDetailsDirty() {
      if (!this.selectedStudent.ect) return false;
      return (
        this.teacherTrainingInstitution !== this.selectedStudent.ect.teacher_training_institution ||
        this.qualificationsAwarded.length !== this.selectedStudent.ect.qualifications_awarded.length ||
        this.qualificationsAwarded.some(x => !this.selectedStudent.ect.qualifications_awarded.includes(x)) ||
        this.qualifiedSubjectsToSave.length !== this.selectedStudent.ect.qualified_subjects.length ||
        this.qualifiedSubjectsToSave.some(x => !this.selectedStudent.ect.qualified_subjects.includes(x)) ||
        this.qualifiedAgeRangesToSave.length !== this.selectedStudent.ect.qualified_age_ranges.length ||
        this.qualifiedAgeRangesToSave.some(x => !this.selectedStudent.ect.qualified_age_ranges.includes(x)) ||
        this.ecfProgramme !== this.selectedStudent.ect.ecf_programme ||
        (this.ecfProgramme !== 'School-based Induction Programme' &&
          this.ecfProvider !== this.selectedStudent.ect.ecf_provider)
      );
    },
    pageDirty() {
      return (
        this.traineeDetailsDirty ||
        (this.selectedStudent.ect && (this.contractDetailsDirty || this.trainingDetailsDirty))
      );
    },
    // Different to EctRegistration as have to allow for ECTs who didn't go through that flow
    canSaveTrainingDetails() {
      return !this.saveTrainingDetailsProcessing;
    },
    showContractDetails() {
      return this.contractType && this.contractType !== 'Permanent';
    },
    showEcfProvider() {
      return this.ecfProgramme && this.ecfProgramme !== 'School-based Induction Programme';
    },
    studentScopedStaffRoles() {
      return this.selectedStudent.staff_roles.map(x => {
        return { ...x.staff, role: x.role, staffRoleId: x.id };
      });
    },
    cohortScopedStaffRoles() {
      return this.selectedStudent.staff_roles_with_cohort_accesss.map(x => {
        return { ...x.staff, role: x.role, staffRoleId: x.id };
      });
    },
    institutionScopedStaffRoles() {
      return this.selectedStudent.staff_roles_with_institution_access.map(x => {
        return { ...x.staff, role: x.role, staffRoleId: x.id };
      });
    },
    canAddStudentScopedRole() {
      return !this.studentScopedRoleProcessing && this.studentScopedRoleId && this.studentScopedStaffIdToAdd;
    },
    staffItems() {
      if (!this.staff) return [];
      const staffIds = this.studentScopedStaffRoles.map(x => x.id);
      return this.staff
        .filter(x => !staffIds.includes(x.id))
        .map(x => ({
          ...x,
          name: x.user.name ? x.user.name + ' (' + x.user.email + ')' : x.user.email,
        }));
    },
    studentScopedRoles() {
      if (!this.roles) return [];
      return this.roles.filter(x => x.student_scope);
    },
    subjectsWithNullOption() {
      return this.subjects.concat({
        name: 'No Subject',
        id: null,
      });
    },
  },
  watch: {
    pageDirty(x) {
      this.dirty = x;
    },
    trainedOutsideEngland(x) {
      if (x) this.trn = '';
    },
  },
  async created() {
    this.name = this.selectedStudent.name;
    this.email = this.selectedStudent.email;
    if (this.selectedStudent.ect) {
      this.ectPackage = this.ectPackages.find(x => x.ref == this.selectedStudent.ect.institution_ect_package).ref;
      this.dateOfBirth = this.selectedStudent.ect.date_of_birth;
      this.trn = this.selectedStudent.ect.trn;
      this.qtsDate = this.selectedStudent.ect.date_awarded_qts;
      this.trainedOutsideEngland = this.selectedStudent.ect.trained_outside_england;
      this.startDate = this.selectedStudent.ect.start_date;
      this.termsAlreadyCompleted = this.selectedStudent.ect.terms_already_completed;
      this.priorReviewsObtained = this.selectedStudent.ect.prior_reviews_obtained;
      this.ecfTermsCompleted = this.selectedStudent.ect.ecf_terms_completed;
      this.ecfCohort = this.selectedStudent.ect.ecf_cohort;
      this.invoiceRef1 = this.selectedStudent.ect.invoice_ref_1;
      this.invoiceRef2 = this.selectedStudent.ect.invoice_ref_2;
      this.ptFteRate =
        this.selectedStudent.ect.fteRate === 1
          ? null
          : this.partTimeOptions.find(x => x.rate === this.selectedStudent.ect.fte_rate);
      this.employmentType = this.selectedStudent.ect.fte_rate === 1 ? 'Full Time' : 'Part Time';
      this.contractType = this.selectedStudent.ect.contract_type;
      this.contractDetails = this.selectedStudent.ect.contract_details;
      this.contractStartDate = this.selectedStudent.ect.contract_start_date;
      this.contractEndDate = this.selectedStudent.ect.contract_end_date;
      this.yearGroups = this.separateOtherFromItems(this.selectedStudent.ect.year_groups, yearGroupItems).items;
      this.otherYearGroup = this.separateOtherFromItems(this.selectedStudent.ect.year_groups, yearGroupItems).other;
      this.teachingSubjects = this.separateOtherFromItems(
        this.selectedStudent.ect.teaching_subjects,
        ectRegistrationSubjects
      ).items;
      this.otherTeachingSubject = this.separateOtherFromItems(
        this.selectedStudent.ect.teaching_subjects,
        ectRegistrationSubjects
      ).other;
      this.mentorName = this.selectedStudent.ect.mentor_name;
      this.mentorEmail = this.selectedStudent.ect.mentor_email;
      this.mentorJobTitle = this.selectedStudent.ect.mentor_job_title;
      this.mentorTrn = this.selectedStudent.ect.mentor_trn;
      this.mentorTrainingDetails = this.selectedStudent.ect.mentor_training_details;
      this.coordinatorName = this.selectedStudent.ect.coordinator_name;
      this.coordinatorEmail = this.selectedStudent.ect.coordinator_email;
      this.coordinatorJobTitle = this.selectedStudent.ect.coordinator_job_title;
      this.teacherTrainingInstitution = this.selectedStudent.ect.teacher_training_institution;
      this.qualificationsAwarded = this.selectedStudent.ect.qualifications_awarded || [];
      this.ecfProgramme = this.selectedStudent.ect.ecf_programme;
      this.ecfProvider = this.selectedStudent.ect.ecf_provider;
      this.qualifiedSubjects = this.separateOtherFromItems(
        this.selectedStudent.ect.qualified_subjects,
        ectRegistrationSubjects
      ).items;
      this.otherQualifiedSubject = this.separateOtherFromItems(
        this.selectedStudent.ect.qualified_subjects,
        ectRegistrationSubjects
      ).other;
      this.qualifiedAgeRanges = this.separateOtherFromItems(
        this.selectedStudent.ect.qualified_age_ranges,
        qualifiedAgeRangesItems
      ).items;
      this.otherQualifiedAgeRange = this.separateOtherFromItems(
        this.selectedStudent.ect.qualified_age_ranges,
        qualifiedAgeRangesItems
      ).other;
      this.registeringEmail = this.selectedStudent.ect.registering_email;
      this.registeringName = this.selectedStudent.ect.registering_name;
      this.registeringRole = this.selectedStudent.ect.registering_role;
      this.slaAgreed = this.selectedStudent.ect.sla_agreed;
      this.marketingAgreed = this.selectedStudent.ect.marketing_agreed;
    }

    this.addressFirstLine = this.selectedStudent.address_first_line;
    this.addressSecondLine = this.selectedStudent.address_second_line;
    this.addressCity = this.selectedStudent.address_city;
    this.addressPostcode = this.selectedStudent.address_postcode;
    this.addressCountry = this.selectedStudent.address_country;
    this.phoneNumber = this.selectedStudent.phone_number;
    this.ittSchool = this.selectedStudent.school;
    this.qualifications = this.selectedStudent.qualifications;
    this.notes = this.selectedStudent.notes;
    this.subjectId = this.selectedStudent.subject?.id;
    this.additionalSubjectId = this.selectedStudent.additional_subject?.id;
    this.isDemo = this.selectedStudent.is_demo;
    this.forceMicrosoftSso = this.selectedStudent.force_microsoft_sso;

    this.$store.dispatch('loadSubjects');
    if (this.userStaffHasPermission('staff.edit')) {
      this.$store.dispatch('loadRoles');
      this.loadStaff();
    }
    if (this.userStaffHasPermission('schools.edit')) {
      this.loadSchools();
    }
    this.getStudentWeeksWithSubjects();
  },
  methods: {
    async loadStaff() {
      this.loadStaffError = false;
      this.loadStaffBusy = true;
      try {
        const [staffResponse, countResponse] = await Promise.all([
          this.$api.get(`/institutions/${this.selectedInstitution.id}/staff?offset=0`),
          this.$api.get(`/institutions/${this.selectedInstitution.id}/staff/count`),
        ]);
        let staff = staffResponse.data;
        const staffCount = countResponse.data.count;

        let offset = staff.length;
        while (true) {
          if (staff.length >= staffCount) break;
          const r = await this.$api.get(`/institutions/${this.selectedInstitution.id}/staff?offset=${offset}`);
          if (r.data.length === 0) break;
          staff = staff.concat(r.data);
          offset += r.data.length;
        }

        this.staff = staff;
      } catch (e) {
        this.loadStaffError = true;
      }
      this.loadStaffBusy = false;
    },
    async getStudentWeeksWithSubjects() {
      if (!this.selectedCohort.has_course) return;
      this.loadWeeksError = '';
      this.loadWeeksProcessing = true;
      try {
        const r = await this.$api.get(`/students/${this.selectedStudent.id}/student-cohort-course-weeks-with-subjects`);
        this.studentWeeksWithSubjects = r.data;
      } catch (e) {
        this.loadWeekSubjectsError = `Cannot check Training Plan Subjects at the moment. Mosaic will not be able to identify any issues associated with updating the ${this.traineeNoun()}'s. If you need to update this ${this.traineeNoun()}'s Subject(s), please try refreshing the page or, if this problem persists, contact support.`;
        console.log(e);
      }
      this.loadWeeksProcessing = false;
    },
    async loadSchools() {
      const r = await this.$api.get(`/institutions/${this.selectedInstitution.id}/schools`);
      this.schools = r.data;
    },
    async retryQts() {
      this.retryQtsProcessing = true;
      this.retryQtsErrorAction = '';
      try {
        await this.$api.post(`/ects/${this.selectedStudent.ect.id}/retry-qts`, {
          date_of_birth: this.dateOfBirth,
          trn: this.trn,
        });
        this.$store.dispatch('loadStudent', this.selectedStudent.id);
      } catch (e) {
        console.log(e);
        this.retryQtsErrorAction = `retry the QTS check`;
      }
      this.retryQtsProcessing = false;
    },
    separateOtherFromItems(selectedItems, presetItems) {
      if (!selectedItems) return [];
      const otherItem = selectedItems.find(x => !presetItems.includes(x));
      return otherItem
        ? {
            items: selectedItems.filter(x => presetItems.includes(x)).concat(['Other']),
            other: otherItem,
          }
        : {
            items: selectedItems,
            other: '',
          };
    },
    otherTransformedSelection(selectedItems, other) {
      if (!selectedItems) return [];
      const otherIndex = selectedItems.findIndex(x => x === 'Other');
      return other && otherIndex !== -1 ? selectedItems.toSpliced(otherIndex, 1).concat([other]) : selectedItems;
    },
    editEmail() {
      this.editEmailDialog = {
        active: true,
        email: this.selectedStudent.email,
        errorMessage: '',
        processing: false,
      };
    },
    renderStaffName(staff) {
      if (staff.name) return staff.name + ' - ' + staff.email;
      else return staff.email;
    },
    async submitEditEmail() {
      if (!validateEmail(this.editEmailDialog.email)) {
        this.editEmailDialog.errorMessage = 'Please supply a valid email';
        this.editEmailDialog.processing = false;
        return;
      }
      this.editEmailDialog.processing = true;
      try {
        const r = await this.$api.put(`students/${this.selectedStudent.id}`, { email: this.editEmailDialog.email });
        this.$store.commit('updateSelectedStudent', r.data);
        this.email = r.data.email;
        this.editEmailDialog.active = false;
      } catch (e) {
        if (e.response?.status === 409) {
          this.editEmailDialog.errorMessage = 'A Mosaic account with that email already exists';
        } else {
          this.editEmailDialog.errorMessage = `Sorry, cannot edit ${this.traineeNoun()}'s email at the moment`;
        }
      }
      this.editEmailDialog.processing = false;
    },
    async saveTraineeDetails() {
      this.saveTraineeDetailsProcessing = true;
      this.saveTraineeDetailsError = '';
      try {
        const r = await this.$api.put(`students/${this.selectedStudent.id}/trainee-details`, {
          name: this.name,
          email: this.email,
          date_awarded_qts: this.qtsDate,
          startDate: this.startDate,
          termsAlreadyCompleted: parseFloat(this.termsAlreadyCompleted),
          priorReviewsObtained: this.termsAlreadyCompleted > 0 ? this.priorReviewsObtained : null,
          trn: this.trn,
          trained_outside_england: this.trainedOutsideEngland,
          date_of_birth: this.dateOfBirth,
          addressFirstLine: this.addressFirstLine,
          addressSecondLine: this.addressSecondLine,
          addressCity: this.addressCity,
          addressPostcode: this.addressPostcode,
          addressCountry: this.addressCountry,
          phoneNumber: this.phoneNumber,
          school: this.ittSchool,
          qualifications: this.qualifications,
          notes: this.notes,
          subjectId: this.subjectId,
          additionalSubjectId: this.additionalSubjectId,
          isDemo: this.isDemo,
          forceMicrosoftSso: this.forceMicrosoftSso,
          institution_ect_package: this.ectPackage,
          ecf_cohort: this.ecfCohort,
          invoice_ref_1: this.invoiceRef1,
          invoice_ref_2: this.invoiceRef2,
        });
        this.$store.commit('updateSelectedStudent', r.data);

        // ECF programme can be updated by this endpoint if selectedPackage changes so need to also update page state
        if (r.data.ect) {
          this.ecfProgramme = r.data.ect.ecf_programme;
        }

        this.traineeDetailsSnackbar = {
          active: true,
          message: 'Success!',
        };
      } catch (e) {
        console.log(e);
        if (e?.response?.status === 409) {
          if (e.response.data.error_code === 'email_conflict') {
            this.saveTraineeDetailsError = `Sorry, cannot save as the email address is already in use`;
          } else {
            this.saveTraineeDetailsError = `Sorry, cannot save the ${this.traineeNoun()} details at the moment`;
          }
        } else {
          this.saveTraineeDetailsError = `Sorry, cannot save the ${this.traineeNoun()} details at the moment`;
        }
      }
      this.saveTraineeDetailsProcessing = false;
    },
    async saveContractDetails() {
      this.saveContractDetailsProcessing = true;
      this.saveContractDetailsError = '';
      try {
        const r = await this.$api.put(`students/${this.selectedStudent.id}/contract-details`, {
          contractType: this.contractType,
          contractDetails: this.showContractDetails ? this.contractDetails : undefined,
          contractStartDate: this.contractStartDate,
          contractEndDate: this.contractEndDate,
          mentorEmail: this.mentorEmail,
          mentorName: this.mentorName,
          mentorJobTitle: this.mentorJobTitle,
          mentorTrn: this.ectPackage === 'ab_and_ecf' ? this.mentorTrn : null,
          mentorTrainingDetails: this.ectPackage === 'ab_and_ecf' ? this.mentorTrainingDetails : null,
          coordinatorEmail: this.coordinatorEmail,
          coordinatorName: this.coordinatorName,
          coordinatorJobTitle: this.coordinatorJobTitle,
          fteRate: parseFloat(this.fteRate),
          yearGroups: this.yearGroupsToSave,
          teachingSubjects: this.teachingSubjectsToSave,
        });
        this.$store.commit('updateSelectedStudent', r.data);
        this.contractDetailsSnackbar = {
          active: true,
          message: 'Success!',
        };
      } catch (e) {
        console.log(e);
        this.saveContractDetailsError = `Sorry, cannot save the contract details at the moment`;
      }
      this.saveContractDetailsProcessing = false;
    },
    async saveTrainingDetails() {
      this.saveTrainingDetailsProcessing = true;
      this.saveTrainingDetailsError = '';
      try {
        const r = await this.$api.put(`students/${this.selectedStudent.id}/training-details`, {
          teacherTrainingInstitution: this.teacherTrainingInstitution,
          qualificationsAwarded: this.qualificationsAwarded,
          qualifiedSubjects: this.qualifiedSubjectsToSave,
          qualifiedAgeRanges: this.qualifiedAgeRangesToSave,
          ecfProgramme: this.ecfProgramme,
          ecfProvider: this.showEcfProvider ? this.ecfProvider : undefined,
          ecfTermsCompleted: this.ecfTermsCompleted,
        });
        this.$store.commit('updateSelectedStudent', r.data);
        this.trainingDetailsSnackbar = {
          active: true,
          message: 'Success!',
        };
      } catch (e) {
        console.log(e);
        this.saveTrainingDetailsError = `Sorry, cannot save the training details at the moment`;
      }
      this.saveTrainingDetailsProcessing = false;
    },
    async addStudentScopedRole() {
      this.studentScopedRoleError = '';
      this.studentScopedRoleProcessing = true;
      try {
        await this.$api.post(`/staff/${this.studentScopedStaffIdToAdd}/roles`, {
          roleId: this.studentScopedRoleId,
          studentId: this.selectedStudent.id,
        });
        this.$store.dispatch('loadStudent', this.selectedStudent.id);
        if (this.studentScopedStaffIdToAdd === this.userStaff.id) {
          await this.$store.dispatch('refreshUser');
        }
        this.$store.commit('clearStudentStaffRoles');
        this.studentScopedStaffIdToAdd = null;
        this.studentScopedRoleId = null;
        this.clearInstitutionStaff();
      } catch (e) {
        this.studentScopedRoleError = `Sorry, cannot add ${this.traineeNoun()} level access at the moment`;
        console.log(e);
      }
      this.studentScopedRoleProcessing = false;
    },
    async removeStudentScopedRole(staff) {
      this.studentScopedRoleError = '';
      try {
        await this.$api.delete(`/staff/${staff.id}/roles/${staff.staffRoleId}`);
        this.$store.dispatch('loadStudent', this.selectedStudent.id);
        if (staff.id === this.userStaff.id) {
          await this.$store.dispatch('refreshUser');
        }
        this.$store.commit('clearStudentStaffRoles');
        this.clearInstitutionStaff();
      } catch (e) {
        this.studentScopedRoleError = `Sorry, cannot remove ${this.traineeNoun()} level access at the moment`;
        console.log(e);
      }
    },
    filterStaff(_, queryText, item) {
      const searchText = queryText.toLowerCase();
      return (
        item.raw.user.name.toLowerCase().startsWith(searchText) ||
        item.raw.user.email.toLowerCase().startsWith(searchText)
      );
    },
    editSchool() {
      this.$router.push({ name: 'TutorEctProgressPage', params: { studentId: this.selectedStudent.id } });
    },
    markInductionComplete() {
      this.inductionCompletionDialog.active = true;
    },
  },
};
</script>

<style scoped>
.left-column {
  flex-basis: 50%;
  padding-right: 16px;
  min-width: 0;
}

.right-column {
  flex-basis: 50%;
  padding-left: 8px;
  padding-right: 8px;
  min-width: 0;
}

.full-width {
  flex-basis: 100%;
  padding-right: 8px;
}
</style>
