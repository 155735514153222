<template>
  <div>
    <v-card v-if="!userStudent.storage_set_up" class="mb-4">
      <v-card-text>
        <div class="pl-1 py-2">
          <p>To view and upload files, you need to (re)link your {{ selectedInstitution.storage_type }} account.</p>
          <p>
            All files you see within the Files area on Mosaic will actually be stored on your
            {{ selectedInstitution.storage_type }}.
          </p>
          <p />
        </div>
        <div class="pl-1 py-2">
          <p>
            The link between your Mosaic account and {{ selectedInstitution.storage_type }} account has expired. To fix
            this, you need to relink your <b>{{ userStudent.storage_email }}</b>
            {{ selectedInstitution.storage_type }} account.
          </p>
          <p />
        </div>

        <v-alert v-if="storageConstraint" class="mb-4" type="info"
          ><div>{{ storageConstraintMessage }}</div>
        </v-alert>

        <v-alert v-if="storageEmailError || wrongAccountWhenRelinkingError" class="mb-4" type="error"
          ><div>{{ storageEmailError || wrongAccountWhenRelinkingError }}</div>
          <div class="mt-2" v-if="storageEmailError">
            Please try again with an allowable One Drive account. You may need to
            <a href="https://login.microsoftonline.com/logout.srf" target="_blank">logout</a> of your One Drive account
            first.
          </div>
        </v-alert>

        <v-btn @click.prevent="navigateToStorageLogin()">
          <v-icon v-if="selectedInstitution.storage_type === 'Google Drive'" class="mr-2">mdi-google-drive</v-icon>
          <v-icon v-if="selectedInstitution.storage_type === 'One Drive'" class="mr-2">mdi-microsoft-onedrive</v-icon>
          <span>{{ !userStudent.storage_email ? 'Link' : 'Relink' }} {{ selectedInstitution.storage_type }}</span>
        </v-btn>
      </v-card-text>
    </v-card>
    <files-list
      v-if="userStudent.storage_set_up || selectedInstitution.storage_type === 'Google Drive'"
      :load-items="loadItems"
      :files-storage-type="selectedInstitution.storage_type"
      :get-file-url="getFileUrl"
      :get-evidence="getEvidence"
      :move-item="moveItem"
      :can-add-as-evidence="showEvidence"
      :page-breadcrumbs="breadcrumbs"
      :storage-set-up="userStudent.storage_set_up"
      :refresh-student="() => $store.dispatch('refreshUser')"
    />
  </div>
</template>

<script>
import FilesList from '../../components/files-list/FilesList.vue';
import { mapGetters, mapState } from 'vuex';
import { startStudentLinkOneDriveFlow, startStudentLinkGoogleDriveFlow } from '../../utils/external-auth';
import { storageConstraintMessage, storageEmailError } from '../../utils/storage-constraints';

export default {
  name: 'StudentFilesListPage',
  components: { FilesList },
  data: () => ({
    hasCourseFiles: null,
    storageConstraint: null,
  }),
  computed: {
    ...mapState(['userStudent', 'selectedInstitution']),
    ...mapGetters(['showEvidence']),
    breadcrumbs() {
      return [
        {
          text: 'Files',
          to: {
            name: 'FilesListPage',
            params: { studentId: this.userStudent.id },
          },
        },
      ];
    },
    wrongAccountWhenRelinkingError() {
      if (this.$route.query.storage_email_error !== 'storage_email_does_not_match') return;

      return `When re-linking your ${this.userStudent.storage_email} ${this.selectedInstitution.storage_type} account you have logged in as ${this.$route.query.storage_email} instead. Please try again with the correct account.`;
    },
    storageEmailError() {
      return storageEmailError(this.$route.query, this.storageConstraint);
    },
    storageConstraintMessage() {
      if (!this.storageConstraint) return false;
      return storageConstraintMessage(this.storageConstraint);
    },
  },
  async created() {
    if (this.selectedInstitution.storage_type === 'One Drive' && !this.userStudent.storage_email) {
      try {
        const r = await this.$api.get(`/storage-constraint`);
        this.storageConstraint = r.data;
      } catch (e) {
        console.log(e);
      }
    }
  },
  methods: {
    async loadItems(folder) {
      if (this.hasCourseFiles === null) {
        const r = await this.$api.get(`/institutions/${this.selectedInstitution.id}/course-files-exist`);
        this.hasCourseFiles = r.data.exists;
      }

      const urlRoot = folder.isCourse
        ? `institutions/${this.selectedInstitution.id}/course-files`
        : `students/${this.userStudent.id}/files`;
      const response = await this.$api.get(`/${urlRoot}/${encodeURIComponent(folder.id)}`);

      // Add editable at the API instead
      let items = response.data.items.map(x => ({
        ...x,
        sortName: x.shared ? `000000000${x.name}` : x.name,
        isCourse: folder.isCourse,
        isMoveTarget: !folder.isCourse && x.type === 'folder',
        icon: {
          name: this.getIconName(x, folder),
          color: null,
          tooltip: this.getTooltip(x),
        },
        editable: !folder.isCourse,
        canLinkEvidence: !folder.isCourse && x.type === 'file' && this.showEvidence,
        filesApiPath: `/${urlRoot}`,
      }));

      if (this.hasCourseFiles && folder.id === '' && !folder.isCourse) {
        const courseFolder = {
          id: '',
          isCourse: true,
          type: 'folder',
          name: 'Course Files',
          icon: {
            name: 'folder-home',
            color: null,
            tooltip: 'This folder contains files1 uploaded by your course leads',
          },
          sortName: '000000000000',
          editable: false,
          filesApiPath: `/institutions/${this.selectedInstitution.id}/course-files`,
        };
        items = [courseFolder].concat(items);
      }

      let presignFilesApiPath = null;
      if (this.selectedInstitution.storage_type === 'Mosaic') {
        presignFilesApiPath = `/presign/students/${this.userStudent.id}/files`;
      }

      return {
        items: items,
        folder: {
          id: folder.id || response.data.folder.id,
          name: folder.name || response.data.folder.name,
          webUrl: folder.webUrl || response.data.folder.webUrl,
          isRootFolder: folder.isRootFolder || false,
          editable: !folder.isCourse,
          isCourse: folder.isCourse,
          isMoveTarget: false,
          uploadDirectToExternalStorage: this.selectedInstitution.storage_type === 'Mosaic',
          filesApiPath: `/${urlRoot}`,
          presignFilesApiPath,
          folderApiPath: `students/${this.userStudent.id}/folders`,
        },
      };
    },
    async getFileUrl(item, download) {
      const urlRoot = item.isCourse
        ? `institutions/${this.selectedInstitution.id}/course-file`
        : `students/${this.userStudent.id}/file`;
      const r = await this.$api.get(`/${urlRoot}/${encodeURIComponent(item.id)}?download=${download}`);
      return r.data.webUrl;
    },
    async getEvidence(evidenceId) {
      const r = await this.$api.get(`/evidence/${evidenceId}`);
      return r.data;
    },
    getIconName(item, folder) {
      if (item.type === 'file') {
        if (item.shared) return 'file-account';
        if (item.isShortcut) return 'file-move';
        return 'file';
      } else {
        if (folder.isCourse) return 'folder-home';
        if (item.shared) return 'folder-account';
        if (item.isShortcut) return 'folder-arrow-right';
      }
      return 'folder';
    },
    getTooltip(item) {
      if (item.shared)
        return 'This item has been shared with you by another Google Drive user. It may need to be shared separately from Google Drive to provide access.';
      if (item.isShortcut)
        return 'This item is a shortcut and may need to be shared separately from Google Drive to provide access.';
      return '';
    },
    async moveItem(item, destinationFolder, currentFolder) {
      await this.$api.post(`/files/${encodeURIComponent(item.id)}/move-item`, {
        destinationId: destinationFolder.id,
        currentParentId: currentFolder.id,
        evidenceId: item.evidence?.id,
      });
    },
    navigateToStorageLogin() {
      this.selectedInstitution.storage_type === 'One Drive'
        ? startStudentLinkOneDriveFlow(this.$route.path)
        : startStudentLinkGoogleDriveFlow(this.$route.path, this.$api);
    },
  },
};
</script>
