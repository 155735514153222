import store from '../store/index';
import api from '@/entrypoints/application/api';
import { refreshAdminInstitutions, getFullAdminInstitution } from '../models/institutions';
import { isLoggedIn, loginUserWithToken, setTokens } from '../utils/auth';
import * as Sentry from '@sentry/vue';
import { startMicrosoftSsoFlow, startGoogleSsoFlow } from '../utils/external-auth';
import { hasPermission, hasPermissionForCohort } from '../mixins/global-mixins';
import { random } from 'lodash';
import { canAuthorInstructorCourseFiles } from '@/utils/staff';

export async function loadStudentData(to, from) {
  // TODO: I'm sure there's a lot of simplifying that can be done in here

  const student =
    store.state.user.students?.find(s => s.id == to.params.studentId) ||
    (store.state.user.student?.id == to.params.studentId && store.state.user.student);
  if (student) {
    await store.commit('updateUserStudent', student);
    return;
  }

  // double equals required as params.studentId can be either a number or a string
  // avoids double api calls on redirect
  if (store.state.selectedStudent?.id == to.params.studentId) {
    return;
  }

  try {
    await store.dispatch('loadStudent', to.params.studentId);
    if (hasPermissionForCohort(store.state.userStaff, 'Admin', store.state.selectedCohort)) {
      await store.dispatch('loadCohort', { cohortId: store.state.selectedCohort.id });
    } else {
      store.dispatch('loadStudentsForStaff', store.state.userStaff);
    }
    await store.dispatch('loadStandardSetForStudent');
    await store.dispatch('loadCurriculum');
  } catch (e) {
    let error = '';
    if (e.response?.status === 403 || e.response?.status === 404) {
      error = 'noAccess';
    } else {
      console.log(e);
      error = 'cannotLoad';
    }
    return { name: 'LoadStudentErrorPage', query: { to: to.fullPath, error } };
  }

  // When switching students, check for redirects where we can't just swap out the studentId
  // e.g. looking at a specific version of a student assignment
  // I don't think there's anyway to switch student while changing the route name (apart from manually changing the URL)
  // Therefore I think it's safe to see if the names are equal
  // Without the name check, back behaviour gets broken after student switching
  if (to.meta.switchStudentPath && to.name === from.name) {
    const newRoute = to.meta.switchStudentPath(to.params.studentId, from);
    return newRoute;
  }
}

export async function loadAdminData(to) {
  if (store.state.user.isAdmin) {
    if (!store.state.adminInstitutions) {
      await refreshAdminInstitutions(api);
    }
    if (to.params.institutionId && store.state.adminInstitutions) {
      await selectAdminInstitution(to.params.institutionId);
      if (to.params.cohortId && store.state.adminInstitution) {
        selectAdminCohort(to.params.cohortId);
      }
    }
  } else {
    await findHomePage(to);
  }
}

async function selectAdminCohort(cohortId) {
  const cohorts = store.state.adminCohorts;
  // Deliberately a ==
  const cohort = cohorts.find(x => x.id == cohortId);
  if (cohort && cohort.id !== store.state.adminCohort?.id) {
    store.commit('updateAdminCohort', cohort);
  }
}

async function selectAdminInstitution(institutionId) {
  const institutions = store.state.adminInstitutions;
  // Deliberately a ==
  const institution = institutions.find(x => x.id == institutionId);
  if (!institution.cohorts) {
    await getFullAdminInstitution(institution.id, api);
  } else if (institution && institution.id !== store.state.adminInstitution?.id) {
    store.commit('updateAdminInstitution', institution);
    store.commit('updateAdminCohorts', institution.cohorts);
  }
}

export async function findHomePage(to) {
  if (to.query.auth_token) {
    setTokens({ auth_token: to.query.auth_token, refresh_token: to.query.refresh_token });
    return { page: to.query.next_step };
  }

  if (!isLoggedIn()) {
    return {
      name: 'LoginPage',
      query: { redirect: to.fullPath },
    };
  }

  if (!store.state.user) {
    try {
      await store.dispatch('refreshUser');
    } catch (e) {
      return { name: 'LoginPage' };
    }
  }

  if (to.query.next_step) {
    return {
      path: to.query.next_step,
      query: {
        storage_email_error: to.query.storage_email_error,
        storage_email: to.query.storage_email,
        institution_id: to.query.institution_id,
      },
    };
  } else {
    if (store.state.user.student) {
      return { name: 'StudentHomePage', params: { studentId: store.state.user.student.id } };
    } else if (store.state.user.staff) {
      if (store.state.user.staff.filter(s => s.staff_roles.length > 0).length === 1) {
        const staff = store.state.user.staff.filter(s => s.staff_roles.length > 0)[0];
        return findInstitutionLevelHomePage(staff);
      }
      return { name: 'UserHomePage' };
    } else if (store.state.user.isAdmin) {
      return { name: 'AdminPage' };
    } else {
      return { name: 'LoginPage' };
    }
  }
}

export function getMentorTrainingPageRedirection(staff, cohort) {
  return hasPermissionForCohort(staff, 'staff.training.edit', cohort) ||
    hasPermissionForCohort(staff, 'staff.training.view', cohort)
    ? 'TutorCohortStaffTrainingPage'
    : hasPermissionForCohort(staff, 'staff.announcements.edit', cohort)
    ? 'CohortAdminStaffAnnouncementsPage'
    : 'TutorAdminCohortEditPage';
}

export function findInstitutionLevelHomePage(staff) {
  // Has content that can only be accessed by the staff home page
  if (staff.has_staff_training || staff.has_any_staff_files || canAuthorInstructorCourseFiles(staff)) {
    return { name: 'TutorHomePage', params: { staffId: staff.id } };
  }

  // Single active cohort
  if (
    staff.staff_roles.every(x => x.cohort_id) &&
    staff.staff_roles.filter(x => x.cohort.status === 'active').length === 1
  ) {
    const cohort = staff.staff_roles.find(x => x.cohort.status === 'active');
    if (staff.institution.config.mentor_training_only) {
      return {
        name: getMentorTrainingPageRedirection(staff, {
          id: cohort.cohort_id,
          institution_id: staff.institution.id,
        }),
        params: { cohortId: cohort.cohort_id },
      };
    } else {
      return {
        name: 'TutorStudentListPage',
        params: { cohort: cohort.cohort_id },
      };
    }
  }

  if (staff.staff_roles.length > 1 || staff.staff_roles.length === 0) {
    return { name: 'TutorHomePage', params: { staffId: staff.id } };
  }

  const staffRole = staff.staff_roles[0];
  const institutionId = staffRole.institution_id;

  // Single student or single school
  if (!institutionId) {
    return { name: 'TutorHomePage', params: { staffId: staff.id } };
  }

  // Single institution does not currently go to TutorHomePage as it has nothing useful on it
  if (!staff.institution.config.early_careers) {
    // Should be extended (in probably a better way) to more permissions
    if (!hasPermission(staff, 'Admin') && hasPermission(staff, 'courseFiles.edit')) {
      return {
        name: 'TutorAdminStudentCourseFilesPage',
        params: { institutionId },
      };
    } else {
      return {
        name: 'TutorCohortListPage',
        params: { institutionId },
      };
    }
  } else {
    return {
      name: 'TutorEctsOverviewPage',
      params: { institutionId },
    };
  }
}

export function ssoMicrosoft() {
  startMicrosoftSsoFlow();
}

export function ssoGoogle() {
  startGoogleSsoFlow(api);
}

export async function activateAccount(to) {
  if (!to.query.token) {
    return { name: 'LoginPage' };
  }

  try {
    const nextStep = await loginUserWithToken(to.query.token, store);
    await store.dispatch('refreshUser');
    return { name: nextStep };
  } catch (e) {
    Sentry.captureException(e);
  }

  return {
    name: 'LoginPage',
    query: {
      error: `The link you've clicked has already been used. Please get in touch with your course leads or support@penrose.education.`,
    },
  };
}

export async function authorise(to) {
  if (to.meta.noAuth) {
    return;
  }
  if (!isLoggedIn()) {
    return {
      name: 'LoginPage',
      query: { redirect: to.fullPath },
    };
  } else {
    if (!store.state.user) {
      try {
        await store.dispatch('refreshUser');
      } catch (e) {
        console.log('Cannot refresh user', e);
        return { name: 'LoginPage', query: { redirect: to.fullPath } };
      }
    }

    if (!store.state.user.detailsFilledIn && !['UserSetupPage', 'LoginPage', 'VerifyEmailPage'].includes(to.name)) {
      return { name: 'UserSetupPage' };
    }
    if (
      store.state.user.forcePasswordChange &&
      store.state.user.detailsFilledIn &&
      !store.state.user.forceMicrosoftSso &&
      !['ForceChangePasswordPage', 'LoginPage'].includes(to.name)
    ) {
      return { name: 'ForceChangePasswordPage' };
    }
  }
}

export function clearSelectedStudent() {
  if (store.state.selectedStudent) {
    store.commit('clearSelectedStudent');
  }
}

export function clearSelectedCohort() {
  if (store.state.selectedCohort) {
    store.commit('clearSelectedCohort');
  }
}

export function clearSelectedSchool() {
  if (store.state.selectedSchool) {
    store.commit('clearSelectedSchool');
  }
}

export function clearSelectedInstitutionIfInMultiple() {
  if (
    (store.state.user.students || store.state.user.student ? [store.state.user.student] : []).length +
      (store.state.user.staff?.length || 0) >
    1
  ) {
    store.commit('clearUserStaff');
    store.commit('clearUserStudent');
  }
}

export async function loadCohort(cohortId, to) {
  try {
    await store.dispatch('loadCohort', { cohortId, throwError: true });
    await store.dispatch('loadGroups', { throwError: true, force: true });
  } catch (e) {
    let error = '';
    if (e.response?.status === 403 || e.response?.status === 404) {
      error = 'noAccess';
    } else {
      console.log(e);
      error = 'cannotLoad';
    }
    // x parameter is so that the router completes the lifecycle if you end up "try again" on the LoadCohortErrorPage
    // and end up back in the same place (the router now thinks you're on a slighty different page)
    return { name: 'LoadCohortErrorPage', query: { to: to.fullPath, error, x: random(1, 100000) } };
  }
}

export async function loadSchool(schoolId) {
  await store.dispatch('loadSchool', { schoolId });
}

export async function selectUserStaffFromInstitutionId(institutionId, to) {
  const staff = store.state.user.staff.find(st => st.institution.id == institutionId);
  return selectUserStaff(staff, to);
}

export async function selectUserStaffFromStaffId(staffId, to) {
  if (!store.state.user.staff) return;
  const staff = store.state.user.staff.find(st => st.id == staffId);
  return selectUserStaff(staff, to);
}

async function selectUserStaff(staff, to) {
  if (!staff) {
    return { name: 'DoNotHaveAccessErrorPage', query: { to: to.fullPath, error: 'not_in_institution' } };
  } else {
    // This chain of id -> staff -> id feels awkward
    store.dispatch('selectUserStaff', { institutionId: staff.institution.id });
    return true;
  }
}

export function checkIfStudentOrTutorOnlyPage(to) {
  if (to.name.startsWith('Tutor') || to.name.startsWith('LegacyTutor')) {
    if (!store.state.user.staff) {
      return { name: 'WrongUserTypeErrorPage', query: { to: to.fullPath, error: 'not_staff' } };
    }
  }

  if (to.name.startsWith('Student') || to.name.startsWith('LegacyStudent')) {
    if (!store.state.user.student) {
      return { name: 'WrongUserTypeErrorPage', query: { to: to.fullPath, error: 'not_student' } };
    }
  }
}

export function startNavigating() {
  store.commit('startNavigating');
}

export function stopNavigating() {
  store.commit('stopNavigating');
}

export function setMobileFriendly(to, from) {
  // Pages are mobile friendly by default
  // This can be switched off at the page or parent level by setting meta.notMobileFriendly
  // If the parent is meta.notMobileFriendly, then it can be overriden at the page level by setting meta.mobileFriendly
  const toNotMobileFriendly = to.matched.some(x => x.meta.notMobileFriendly) && !to.meta.mobileFriendly;
  const fromNotMobileFriendly = from.matched.some(x => x.meta.notMobileFriendly) && !from.meta.mobileFriendly;

  if (toNotMobileFriendly === fromNotMobileFriendly) {
    return;
  }

  if (toNotMobileFriendly) {
    document.getElementById('viewport').setAttribute('content', 'viewport-fit=cover');
  } else {
    document.getElementById('viewport').setAttribute('content', 'width=device-width,initial-scale=1');
  }
}

export function findStudentPage(manualPageName) {
  return to => {
    const user = store.state.user;
    const student = user.students?.[0] || user.student;
    if (student) {
      const pageName = manualPageName || to.name.split('Student')[1];
      return { name: pageName, params: { ...to.params, studentId: student.id }, query: to.query };
    } else {
      return { name: 'Home' };
    }
  };
}

export function mustBeAdmin() {
  if (!store.state.user.isAdmin) return { name: 'DoNotHaveAccessErrorPage' };
}

export function mustBeStaff() {
  if (!store.state.user.staff || store.state.user.staff.length === 0) return { name: 'DoNotHaveAccessErrorPage' };
}
